button {
    border: none;
    border-radius: 5px;
    padding: 3px;
    margin-bottom: 20px;
    margin-right: 10px;
}

    button.button-move, button.button-add {
        color: #fff;
        background-color: #3f51b5;
    }

    button.button-delete {
        color: #fff;
        background-color: #f44336;
    }

    .overflow-elipsies{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .filter-exception-regex {
        width: 60em;
    }

    .filter-result-message {
        width: 30em;
    }

    .width-100em{
        width: 100em;
    }

.width-100em-sub-div .ra-input div{
   width: 100em;
}

.is-error-allowed {
    width: 8em;
}

.width-220px{
    width: 220px;
}


.width-70px {
    width: 70px;
}

.width-60px {
    width: 60px;
}